const NavBar = () => {
    return (

        <nav className="navbar bg-dark text-light mb-5">
            <div className="container-fluid">
                <h3 className="mx-auto">Previsão Meteorológica</h3>
            </div>
        </nav>

    );

}

export default NavBar;